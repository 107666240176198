/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FeatureToggles, MicroFrontendAppShellProps } from '@westwing/appshell_types';
import { isTablet } from '@westwing/ui-kit/breakPoints';
import { Links, TabsNavItem } from '../types/Navigation';
import { Theme } from '../theme';
import { HeaderModeType, Tabs } from '../types/Header';
import useDeviceType from '../hooks/useDeviceType';
import { PageType } from '../types/PageType';
import ErrorBoundaryHeader from './ErrorBoundaryHeader';
import { getSmartAppBannerUrl } from './helpers/getSmartAppBannerUrl';
import { HeaderContextProvider } from 'Components/HeaderContext';
import Header, { HeaderProps } from 'Components/Header';
import { Logo } from 'Components/Logo';
import ErrorBoundary from 'Components/ErrorBoundary';
import { OnboardingTooltipConfig } from 'types/OnboardingTooltip';
import { OnboardingTagIcon } from 'Components/OnboardingTooltip';

const navItems: TabsNavItem[] = [
    {
        linkText: 'Shop',
        linkUrl: Links.BELLA,
        isSelected: true,
        trackingAction: 'Club tab click',
    },
    {
        linkText: 'Club',
        linkUrl: Links.CLUB,
        isSelected: false,
        trackingAction: 'Shop tab click',
    },
];

export interface HeaderMfProps
    extends Omit<HeaderProps, 'stickyConfig' | 'smartAppBannerConfig' | 'smartAppBannerUrl'>,
        MicroFrontendAppShellProps {
    isStickyEnabled: boolean;
    showOnboardingTooltip?: boolean;
    // temporary fix while appshell is not updated on new npm registry
    featureToggles: FeatureToggles;
}

const HeaderMf = ({
    tabsConfig = navItems,
    middleAreaComponent,
    iconTrayComponents,
    infoBlockComponents,
    headerMode,
    burgerMenuConfig,
    miniHeaderConfig,
    isStickyEnabled = true,
    onStickyChange,
    stickyAreaComponents,
    afterHeaderComponent,
    trackingHelpers,
    routingHelpers,
    logger,
    hasBarBanner,
    logoConfig,
    t,
    showOnboardingTooltip,
    shouldShowAppBanner = false,
    featureToggles,
}: HeaderMfProps): JSX.Element | null => {
    const isSecondRowHeaderMode = useMemo(() => headerMode === HeaderModeType.SECOND_ROW, [headerMode]);
    const [isStuck, setIsStuck] = useState(isSecondRowHeaderMode);
    useEffect(() => {
        // this is needed to make it work when navigating clientside
        if (isSecondRowHeaderMode) {
            setIsStuck(true);
        }
    }, [isSecondRowHeaderMode]);

    const onStickyChangeHandler = useCallback(
        (newStuckValue: boolean): void => {
            setIsStuck(newStuckValue);
            if (onStickyChange) {
                onStickyChange(newStuckValue);
            }
        },
        [onStickyChange]
    );

    try {
        const { relativeUrl, pageType } = routingHelpers.useCurrentPageType();

        useEffect(() => {
            if (!isTablet() && pageType === PageType.PDP) {
                trackingHelpers.ga.trackVariation(
                    'p100OneWestwingSmallHeader',
                    featureToggles.p100OneWestwingSmallHeader
                );
            }
            // we need to disable the rule as it requires trackingHelpers.ga to be in deps array.
            // But trackingHelpers.ga keeps updating and triggers event being called several times
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [pageType, featureToggles.p100OneWestwingSmallHeader]);

        // This is needed because of Alice. Alice will send an event instructing to show the tooltip
        const [onboardingTooltipConfig, setOnboardingTooltipConfig] = useState<OnboardingTooltipConfig>({
            show: false,
            tooltipFor: Tabs.Club,
            headerText: t('OwwHeader_Club tooltip headline'),
            text: t('OwwHeader_Club tooltip text'),
            TooltipIcon: <OnboardingTagIcon />,
            trackingAction: 'Club Onboarding Tooltip',
            trackingLabel: relativeUrl,
        });

        useEffect(() => {
            setOnboardingTooltipConfig(config => ({ ...config, show: showOnboardingTooltip ?? false }));
        }, [showOnboardingTooltip]);

        const { getByUserAgent } = useDeviceType();
        const { host } = routingHelpers;
        const [smartAppBannerUrl, setSmartAppBannerUrl] = useState('');
        useEffect(() => {
            // needs to be wrapped via hook as uses window object that is not available on SSR
            const url = getSmartAppBannerUrl(host, getByUserAgent(), featureToggles);
            setSmartAppBannerUrl(url);
        }, [getByUserAgent, host, featureToggles]);

        return (
            <ErrorBoundary fallbackComponent={<ErrorBoundaryHeader tabsConfig={navItems} />}>
                <Theme theme={{ featureToggles }}>
                    <HeaderContextProvider
                        value={{
                            app: 'SHOP',
                            t,
                            logger,
                            trackingHelpers: {
                                ga: {
                                    trackNonInteractiveGenericEvent: trackingHelpers.ga.trackGenericEvent,
                                    trackInteractiveGenericEvent: trackingHelpers.ga.trackInteractiveGenericEvent,
                                },
                                braze: {
                                    logCustomEvent: trackingHelpers.braze.logCustomEvent,
                                },
                                hotjar: trackingHelpers.hotjar,
                                segment: trackingHelpers.segment,
                                useCurrentPageType: routingHelpers.useCurrentPageType,
                            },
                            useNewLogo: featureToggles.p100UseNewCiLogo,
                        }}
                    >
                        <Header
                            smartAppBannerUrl={smartAppBannerUrl}
                            shouldShowAppBanner={shouldShowAppBanner}
                            headerMode={headerMode}
                            burgerMenuConfig={burgerMenuConfig}
                            tabsConfig={tabsConfig}
                            iconTrayComponents={iconTrayComponents}
                            isStuck={isStuck}
                            middleAreaComponent={
                                <>
                                    <Logo
                                        $appear={!isSecondRowHeaderMode}
                                        $mobileOnly
                                        $isHidden={!isStuck}
                                        href={tabsConfig[0].linkUrl}
                                        linkComponent={logoConfig?.linkComponent}
                                    />
                                    {middleAreaComponent}
                                </>
                            }
                            isStickyEnabled={isStickyEnabled}
                            miniHeaderConfig={miniHeaderConfig}
                            infoBlockComponents={infoBlockComponents}
                            onStickyChange={onStickyChangeHandler}
                            stickyAreaComponents={stickyAreaComponents}
                            afterHeaderComponent={afterHeaderComponent}
                            logoConfig={logoConfig}
                            logoHref={tabsConfig[0].linkUrl}
                            hasBarBanner={hasBarBanner}
                            onboardingTooltipConfig={onboardingTooltipConfig}
                        />
                    </HeaderContextProvider>
                </Theme>
            </ErrorBoundary>
        );
    } catch (e) {
        if (logger) {
            logger.error(e);
        }
        return <ErrorBoundaryHeader tabsConfig={navItems} />;
    }
};

export default HeaderMf;
