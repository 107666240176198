export enum Links {
    BELLA = '/',
    CLUB = '/c',
    STUDIO = '/m/westwingstudio/',
}

export interface TabsNavItem {
    linkText: string;
    linkUrl: string;
    isSelected: boolean;
    trackingAction: string;
    linkAttributes?: Record<string, number | boolean | string>;
    /** This will avoid login overlay from appearing when customer clicks on it while logged out */
    noOverlay?: true;
    testId?: string;
}

export interface HeaderLinkProps {
    href: string;
    ariaLabel?: string;
}
